<template>
  <div id="screenPopup">
    <van-popup style="height: 100%;border-radius: 5px 0 0 5px;width: 75%;" @close="close" position="right"
      v-model="isShow">
      <div class="content_1">
        <div>订单类型</div>
        <div class="choose_items">
          <div v-for="(item, index) in orderList" :key="index" class="choose_item"
            :class="{ 'choose_item_focus': orderType === item.id }" @click="orderType = item.id">{{ item.text }}</div>
        </div>
      </div>
      <div class="content_1 content_2" v-if="orderType !== 13">
        <div>起止时间</div>
        <div @click="showTimeLayer = !showTimeLayer" class="time_box">
          <div class="choose_item">{{ timeArr[0] }}</div>
          <div>~</div>
          <div style="margin-left: 10px;margin-right: 0;" class="choose_item">{{ timeArr[1] }}</div>
        </div>
        <div class="input_box">
          <van-icon size="16" name="search" />
          <input v-model="orderNum" class="input" placeholder="订单号" type="number">
        </div>
      </div>
      <div class="content_1" v-if="[3, 4, 7, 12,14].indexOf(orderType) >= 0">
        <div>品类</div>
        <div class="choose_items">
          <div @click="changeBrandType(3)" :class="{ 'choose_item': 1, 'choose_item_focus': brandType == 3 }">美妆类</div>
          <div @click="changeBrandType(4)" :class="{ 'choose_item': 1, 'choose_item_focus': brandType == 4 }">大健康类</div>
        </div>
      </div>
      <template v-else-if="[1, 2, 11].indexOf(orderType) >= 0">
        <div class="content_1">
          <div>美妆类</div>
          <div class="choose_items">
            <div @click="changeBrandType(3)" :class="{ 'choose_item': 1, 'choose_item_focus': brandType == 3 }">梵洁诗</div>
            <div @click="changeBrandType(1)" :class="{ 'choose_item': 1, 'choose_item_focus': brandType == 1 }">香蜜</div>
          </div>
        </div>
        <div class="content_1">
          <div>大健康类</div>
          <div class="choose_items">
            <div @click="changeBrandType(4)" :class="{ 'choose_item': 1, 'choose_item_focus': brandType == 4 }">蔓薇</div>
          </div>
        </div>
      </template>
      <div class="btns_box">
        <van-button @click="reset" size="small" round type="default">重置</van-button>
        <van-button @click="confirm" size="small" color="#ff98a0" round type="info">确定</van-button>
      </div>
    </van-popup>
    <van-calendar :max-date="new Date()" :min-date="new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 365))" color="#FF98A0"
      v-model="showTimeLayer" type="range" @confirm="timeConfirm" />
  </div>
</template>

<script>
import { formatTime } from "../utils/formatTime";
export default {
  name: "screenPopup",
  props: {
    show: false,
    type: {
      type: Number,
      default: 0
    },
    brand: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      showTimeLayer: false,
      timeArr: ['', ''],
      brandType: 3,
      orderType: 8,
      orderNum: '',
      isShow: false,
      orderList: [
        { id: 1, text: '我的订货订单', url: this.userHost + '/rolorweb/html/order/personal.html?' },
        { id: 2, text: '我的连锁店订单', url: this.userHost + '/rolorweb/html/order/lower.html?' },
        // { id: 3, text: '蜜店精选订单', url: this.baseHost + '/7999/MiDian/html/buyer/personal.html?' },
        // { id: 4, text: '我收到的蜜店精选订单', url: this.baseHost + '/7999/MiDian/html/seller/lower.html?' },
        // {id: 5, text: '我的限时精选订单', url: '../html/redEnvelope/myRed.html?'},
        { id: 6, text: '蜜豆商城订单', url: this.userHost + '/rolorweb/html/honeyBeans/order_list.html?' },
        { id: 7, text: '物料商城订单', url: this.baseHost + '/7999/html/supplies/orderlist.html?' },
        // { id: 8, text: 'VIP订单礼包', url: this.baseHost + '/7999/html/treasure/order_list.html?' },
        // { id: 9, text: '团购兑换订单', url: '/#/order/ExchangeList?' },
        { id: 12, text: '特惠专区订单', url: '/#/special/orderList?' },
        { id: 13, text: '课程订单', url: '/#/course/orderList?' },
        { id: 14, text: '零售券兑换订单', url: '/#/retailZone/orderList?' },
      ]
    }
  },
  watch: {
    //组件close事件会更改值 父组件传递的值不允许在子组件中进行修改
    'show': function (e) {
      this.isShow = this.show
    }
  },
  created() {
    console.log(this.type)
    if (this.type > 0) this.orderType = this.type;
    if (this.brand) this.brandType = this.brand;
    this.getDefaultTime()
    // console.log( window.location.href)
  },
  methods: {
    timeConfirm(timeArr) {
      timeArr[0] = formatTime(timeArr[0], 'YY-MM-DD', '-')
      timeArr[1] = formatTime(timeArr[1], 'YY-MM-DD', '-')
      this.timeArr = timeArr
      this.showTimeLayer = false
    },
    close() {
      this.$emit('close')
    },
    getDefaultTime() {
      this.timeArr[0] = formatTime(new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 60)), 'YY-MM-DD', '-')
      this.timeArr[1] = formatTime(new Date(), 'YY-MM-DD', '-')
    },
    changeBrandType(e) {
      this.brandType = e
    },
    reset() {
      this.getDefaultTime()
      this.brandType = 1
      this.orderType = this.type
      this.orderNum = ''
    },
    confirm() {
      let orderType = this.orderType;
      if (orderType != this.type) {
        let item = this.orderList.find(it => it.id === orderType);
        if (!item) return
        // return
        window.location.href = item.url + "startTime=" + this.timeArr[0] + "&endTime=" + this.timeArr[1]
          + "&searchVal=" + this.orderNum + "&brandId=" + this.brandType;
      } else {
        this.$emit('search', {
          timeArr: this.timeArr,
          brandType: this.brandType,
          orderType: this.orderType,
          orderNum: this.orderNum
        });
      }
    }
  }
}
</script>

<style lang="scss">
#screenPopup {
  text-align: start;

  .content_1 {
    padding-top: 15px;
    padding-left: 20px;
  }

  .content_2 {
    padding-bottom: 8px;
    border-bottom: 12px solid rgb(242, 242, 242);

    .time_box {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .choose_item {
        margin-right: 10px;
        border-radius: 500px;
        padding: 6px 10px;
        background-color: rgb(242, 242, 242);
        border: 1px solid rgb(242, 242, 242);
        color: #999;
      }
    }

    .input_box {
      width: 90%;
      border-radius: 500px;
      background-color: rgb(242, 242, 242);
      box-sizing: border-box;
      padding: 5px 10px;
      margin-top: 8px;
      display: flex;

      .input {
        background-color: transparent;
        border: none;
        flex: 1;
      }
    }
  }

  .choose_items {
    display: flex;
    flex-wrap: wrap;

    .choose_item {
      margin-right: 10px;
      border-radius: 500px;
      padding: 6px 10px;
      background-color: rgb(242, 242, 242);
      margin-top: 15px;
      border: 1px solid rgb(242, 242, 242);
    }

    .choose_item_focus {
      border: 1px solid rgb(255, 152, 160);
      color: rgb(255, 152, 160);
      background-color: rgb(255, 245, 246);
    }
  }

  .btns_box {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    border-top: 1px solid #f2f2f2;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    width: 100%;
    box-sizing: border-box;

    .van-button {
      width: 120px;
      height: 35px;
    }
  }
}</style>