<template>
  <div>
    <van-cell v-for="item in orderList" :key="item.orderId" @click="viewDetail(item)">
      <div class="order-num">
        <div style="display: flex;align-items: center">
          <span class="or-bid" v-if="item.BrandName">{{ item.BrandName }}</span>
          <span class="or-txt">订单号</span>
          <span class="or-no">{{ item.OrderNum }}</span>
          <span class="copy-btn" @click.stop v-clipboard:copy="item.OrderNum" v-clipboard:success="copyOrderNum">复制</span>
          <van-icon name="arrow" />
        </div>
        <div class="right-cont">
          <p class="or-state real-auth" v-if="item.IsCertification">
            <van-icon name="success"></van-icon>
            <span class="mtxt">已实名认证</span>
          </p>
          <p class="or-state real-auth col-gray" v-else-if="item.CertificationName">
            <i class="icon-delete"></i>
            <span class="mtxt">{{ item.CertificationName }}</span>
          </p>
          <span class="font-pink" v-if="item.stateTxt">{{ item.stateTxt }}</span>
          <div class="cus-count-down" v-if="orderType == 12 && !item.state && (item.overdueTimes - Date.now() > 0)">
            <van-count-down style="color:#999" :time="(item.overdueTimes - (Date.now()))" />后自动取消
          </div>
        </div>
      </div>
      <div class="product-img" v-if="item.ProductList.length > 1">
        <img v-for="(product, index) in item.ProductList" :key="index" v-lazy="product.ImageUrl">
      </div>
      <div class="product" v-else-if="item.ProductList.length > 0">
        <img v-lazy="item.ProductList[0].ImageUrl">
        <div class="prod-desc" v-if="orderType === 12">
          <div class="mui-ellipsis">
            <span>{{ item.ProductList[0].GoodsName }}</span>
          </div>
        </div>
        <div class="prod-desc" v-else>
          <div style="display: flex;align-items: center"><span style="max-width: 80%;display: inline-block;"
              class="ellipsis1">{{ item.ProductList[0].GoodsName }}</span>&nbsp;&nbsp;x&nbsp;&nbsp;<span>{{
                item.ProductList[0].Count
              }}</span>
          </div>
          <div class="mui-ellipsis">
            <span>{{ item.ProductList[0].goodsSpecifications }}</span>
          </div>
          <div class="mui-ellipsis font-pink">¥{{ item.ProductList[0].Amount }}</div>
        </div>
      </div>
      <div class="order-desc" v-if="orderType === 1">
        <p>下单人：{{ item.OrderUserName }}({{ item.OrderUserLevelName }})</p>
        <p>下单时间：{{ item.OrderDateTime }}</p>
        <!-- <p>下单人授权编码：{{ item.OrderUserAccreditNum }}</p> -->
      </div>
      <div class="order-amount">
        共{{ item.totalNum }}件,
        <template v-if="item.PayDisplay">（支付方式：{{ item.PayDisplay }}），</template>
        商品总额：
        <!----><span class="font-pink">￥{{ item.totalAmount }}</span>
      </div>
      <div class="order-desc" v-if="[12].indexOf(orderType) >= 0">
        <p>下单时间：{{ item.createTimes }}</p>
      </div>
      <div class="order-btn" @click.stop="" v-if="item.btnList.length > 0">
        <div class="cus-count-down" v-show="orderType != 12 && !item.state">
          <van-count-down style="color:#999" :time="(item.overdueTimes - (Date.now()))"
            v-if="(item.overdueTimes - Date.now() > 0)" />后自动取消
        </div>
        <div class="btn-list">
          <template v-for="(it, idx) in item.btnList">
            <button :class="it.className" :key="idx" type="button" @click.stop="handleBtnClick(it, item)"
              v-if="it.showType === 1">
              {{ it.text }}
            </button>
            <p :key="it" v-else>{{ it.text }}</p>
          </template>
        </div>
      </div>
      <div class="reason" v-if="item.cancelReason">
        <span class="font-pink">取消原因</span>：{{ item.cancelReason }}
      </div>
    </van-cell>

  </div>
</template>

<script>
export default {
  name: "List-Item",
  props: {
    orderList: {
      type: Array,
      default: []
    },
    tabId: {
      type: Number,
      default: 0
    },
    orderType: {
      type: Number,
      default: 1 //  10兑换订单
    }
  },
  data() {
    return {
      isApp: "0"
    }
  },
  created() {
    let { isapp } = this.$route.query;
    this.isApp = isapp;
  },
  methods: {
    viewDetail(item) {
      window.sessionStorage.setItem("payRefresh", 1);
      this.$router.push("/Recharge/order_detail?orderId=" + item.orderId + "&orderType=" + this.orderType + "&isapp=" + this.isApp)
      // window.location.href = this.baseHost + "/#/Recharge/order_detail?orderId=" + item.orderId + "&orderType=" + this.orderType;
    },
    handleBtnClick(it, item) {
      if (it.type === 1) this.$emit("cancel", item);       //取消订单
      else if (it.type === 2) {//去支付
        this.$emit("pay", item)
      } else {
        this.$emit("tap", it.type, item)
      }
    },
    copyOrderNum(num) {
      this.$toast('复制成功')
    }
  }
}
</script>

<style lang="scss" scoped>
.van-list {
  padding-bottom: 40px;

  .prompt {
    color: #ff98a0;
    padding: 6px 15px;
    background-color: #fef2f3;
  }

  .van-cell {
    margin-top: 10px;
  }

  .order-num {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;

    .or-bid {
      color: #fff;
      float: left;
      padding: 0 2px;
      border-radius: 3px;
      margin-right: 5px;
      background-color: #ff98a0;
    }

    .or-txt {
      color: #333;
      font-size: 10px;
      line-height: 16px;
      padding: 0 3px;
      margin-right: 3px;
      border: 1px solid #333;
      border-radius: 2px;
    }

    .or-no {
      position: relative;
      max-width: 140px;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
    }

    .copy-btn {
      display: inline-block;
      font-size: 10px;
      margin-right: 5px;
      padding: 0px 8px;
      line-height: 18px;
      border-radius: 500px;
      background-color: #999;
      color: #f2f2f2;
    }

    .van-icon-arrow {
      display: inline-block;
      vertical-align: middle;
    }

    .or-state {
      position: relative;
      color: #ff534a;
      height: 22px;
      line-height: 20px;
      border-radius: 30px;
      padding: 0 8px 0 18px;
      display: inline-block;
      border: 1px solid #ff4443;

      .van-icon-success {
        position: absolute;
        top: 1px;
        left: 1px;
        font-size: 18px;
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
      }

      .icon-delete {
        position: absolute;
        top: 3px;
        left: 4px;
        font-size: 14px;

        &:before {
          content: '\e94c';
          display: inline-block;
        }
      }

      &.col-gray {
        color: #333;
        border: 1px solid #333;
      }
    }

    p {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 10px;
    }
  }

  .product {
    font-size: 0;
    position: relative;
    padding-top: 10px;
    display: flex;

    img {
      width: 80px;
      height: 80px;
    }

    .prod-desc {
      width: calc(100% - 95px);
      font-size: 12px;
      vertical-align: top;
      padding: 10px 0 0 15px;

      p {
        color: #333333;

        &:nth-child(1) {
          font-size: 14px;
          width: 90%;
        }

        &:nth-child(2) {
          font-size: 12px;
        }
      }
    }

    .prod-num {
      font-size: 12px;
      color: #999;
      position: absolute;
      right: 15px;
      top: 20px;
    }
  }

  .product-img {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 10px 0;

    img {
      width: 80px;
      height: 80px;
      margin-left: 10px;
    }
  }

  .order-desc {
    border-top: 1px solid #f1f1f1;
    padding: 10px 15px;

    p {
      font-size: 12px;
      color: #333;
    }
  }

  .order-amount {
    color: #999;
    display: flex;
    font-size: 12px;
    align-items: center;
    padding: 10px 15px;
    position: relative;

    span {
      font-size: 14px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 15px;
      right: 0;
      height: 1px;
      background-color: #f1f1f1;
    }
  }

  .order-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    text-align: right;
    padding-top: 10px;
    border-top: 1px solid #f1f1f1;

    .btn-list {
      flex: 1;
      text-align: right;
    }

    button {
      font-size: 14px;
      color: #999;
      border: 1px solid #cccccc;
      border-radius: 20px;
      padding: 3px 16px;
      background: #fff;
      margin-left: 15px;
      /*margin-right: 5px;*/
      line-height: 20px;

      &:last-child {
        margin-right: 0;
      }

      &.col {
        border-color: #ff98a0;
        color: #ff98a0;
      }
    }

    p {
      float: left;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .reason {
    padding: 11px 15px;
    text-align: right;
    border-top: 1px solid #f1f1f1;
  }

  .font-pink {
    color: #ff98a0;
  }

  .van-list__loading {
    .van-list__loading-text {
      font-size: 16px;
    }
  }
}

.cus-count-down {
  display: flex;
  align-items: center;
  color: #999;
}
</style>