<template>
  <div class="exchange-content">
    <div class="head-content" ref="element">
      <div class="or-header">
        <div class="head-left">
          <div class="head-type">
            <p @click="showScreenPopup = !showScreenPopup" v-if="isApp === '0'">课程订单<span class="arrow-top"></span></p>
            <p v-else>课程订单<span class="arrow-top"></span></p>
          </div>
        </div>
        <div class="head-right" @click="showScreenPopup = !showScreenPopup" v-if="isApp != '1'">
          筛选 <span class="icon-sx1"></span>
        </div>
      </div>
      <div class="or-nav">
        <van-tabs v-model="tabId" :before-change="onTabBeforeChange" @change="changeState" :sticky="true">
          <van-tab v-for="(item, index) in tabList" :key="index">
            <div slot="title">
              {{ item.text }}
              <!---->
              <template v-if="item.Count > 0">({{ item.Count }})</template>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <van-list v-model="loading" :finished="finished" @load="pullUpLoadMore" ref="orderList">
      <ListItem :order-list="orderList" :tab-id="tabId" :order-type="13" @cancel="handleCancel" @pay="confirmPay" @tap="handleButton" />
    </van-list>
    <div class="no-data" v-if="finished && orderList.length < 1">
      <div class="bg-circle">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210414140256672.png">
      </div>
    </div>
    <van-popup v-model="showCancelLayer">
      <Cancel @cancel="confirmCancel" />
    </van-popup>

    <van-popup v-model="showCodeLayer" round>
      <div class="verify-cont" v-if="verifiCodeInfo">
        <img :src="verifiCodeInfo.offImg" />
        <div class="propmt" v-if="verifiCodeInfo.offTxt">{{ verifiCodeInfo.offTxt }}</div>
      </div>
    </van-popup>
    <screenPopup @close="showScreenPopup = false" :type="13" :show="showScreenPopup" @search="search"></screenPopup>
  </div>
</template>

<script>
import ListItem from "../../components/Order/ListItem";
import Cancel from "../../components/Order/Cancel";
import screenPopup from "../../components/screenPopup";


export default {
  name: "ExchangeList",
  components: { ListItem, Cancel, screenPopup },
  data() {
    return {
      tabId: 0,
      tabList: [
        { text: '全部', id: -1, Count: 0 },
        { text: '待付款', id: 0, Count: 0 },
        { text: '已付款', id: 1, Count: 0 },
        { text: '已取消', id: 3, Count: 0 }
      ],
      finished: false,
      loading: false,
      orderList: [],
      searchVal: '',
      pageIndex: 0,
      pageSize: 10,
      showCancelLayer: false,
      currentOrder: 0, //当前选中的订单
      showPayLayer: false,
      orderCountByBid: 0,
      brandId: 4,
      showScreenPopup: false,
      startTime: '',
      endTime: '',
      isApp: "0",
      search: "",
      showCodeLayer: false,
      verifiCodeInfo: null,//核销码
      payMayObj: null
    }
  },
  created() {
    let { isapp } = this.$route.query;
    this.isApp = isapp;
    this.getWXShow()
  },
  methods: {
    getWXShow() {
      this.get('/Base/Pay/GetPayWayIsOpen', "", 2).then(res => {
        if (res.code === 1) {
          let obj = res.response;
          this.payMayObj = obj;
        }
      })
    },
    //上拉加载更多
    pullUpLoadMore() {
      this.pageIndex++;
      this.getOrderList();
    },
    //获取订单列表
    getOrderList() {
      this.loading = false
      if (this.loading) return
      this.loading = true;
      this.post('/GroupBuy/OnlineCourse/GetCourseOrderList', {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        stateType: this.tabList[this.tabId].id
      }, true, 2).then(res => {
        this.loading = false;
        if (res.code === 1) {
          let { orderList, orderStateCount } = res.response;
          if (orderList.length < this.pageSize) {
            this.finished = true;
          }
          this.tabList.forEach((it) => {
            if (it.id === 4) return
            it.Count = orderStateCount["state" + it.id] || 0
          });
          orderList.forEach(item => {
            item.OrderNum = item.orderNum;
            let totalNum = 0;
            item.ProductList = (() => {
              let arr = [];
              item.courseList.forEach(it => {
                arr.push({
                  ImageUrl: it.courseImg,
                  GoodsName: it.courseTile,
                  Amount: it.coursePrice,
                  Count: it.courseCount,
                  goodsSpecifications: it.goodsSpecifications
                })
                totalNum += it.courseCount
              });
              return arr;
            })();
            item.totalNum = totalNum;
            item.btnList = (() => {
              let arr = [];
              if ([0].indexOf(item.state) >= 0) arr.push({
                text: '取消订单',
                type: 1,
                className: '',
                showType: 1
              });
              if (item.state === 0) arr.push({ text: '去付款', type: 2, className: 'col', showType: 1 });
              if (item.offDetails) arr.push({ text: '二维码', type: 3, className: 'col', showType: 1 })
              return arr;
            })();
            item.totalAmount = item.payAmount
          });
          if (this.pageIndex === 1) this.orderList = [];
          this.orderList = this.orderList.concat(orderList);
        }
      });
    },
    onTabBeforeChange() {
      return !this.loading
    },
    //点击状态、根据状态获取数据
    changeState(id) {
      this.tabId = id;
      this.initOrderParam();
    },
    initOrderParam() {
      this.loading = true;
      this.finished = false;
      this.pageIndex = 1;
      this.orderList = [];
      this.getOrderList(-1);
    },
    //点击取消订单
    handleCancel(item) {
      this.$dialog.confirm({ title: '提示', message: '是否确认取消该订单' }).then(() => {
        this.currentOrder = item;
        this.showCancelLayer = true;
      }).catch(() => {
      });
    },
    //确认取消订单
    confirmCancel(e) {
      let { type, message } = e;
      this.showCancelLayer = false;
      let { orderId } = this.currentOrder
      if (type === 1) return
      this.$toast.loading({ message: '订单取消中,,,', overlay: true, duration: 0 })
      let params = {
        orderId: orderId,
        reasons: message,
        v: 1,
        operationUserId: 0
      }
      this.post('/GroupBuy/OnlineCourse/CancelOrder', params, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          this.$toast("取消成功!");
          this.deleteFromList(orderId);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //确认支付
    confirmPay(item) {
      return this.$router.push("/Recharge/order_detail?orderId=" + item.orderId + "&orderType=13&isapp=" + this.isApp);
    },
    //从数组里删除数据
    deleteFromList(orderId) {
      let idx = this.orderList.findIndex((it => {
        return it.orderId === orderId
      }));
      if (idx >= 0) {
        --this.tabList[this.tabId].Count;
        this.orderList.splice(idx, 1);
      }
      if (this.orderList.length < 4) {
      }
    },
    //点击其他按钮
    handleButton(type, order) {
      //核销二维码
      if (type === 3 && order.offDetails) {
        this.verifiCodeInfo = order.offDetails;
        this.showCodeLayer = true;
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.exchange-content {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: #f8f8f8;

  .no-data {
    padding-top: 120px;
    text-align: center;

    .bg-circle {
      position: relative;
      margin-top: 10%;
      margin-bottom: 6%;

      img {
        max-width: 36%;
      }

      .icon-order {
        margin-left: -16px;

        &:before {
          content: '\e966';
        }
      }
    }

    .title {
      font-size: 18px;
      color: #333333;
      line-height: 30px;
      padding-top: 10px;
    }

    .tip {
      color: #999999;
      line-height: 30px;
    }
  }

  .head-content {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;

    .or-header {
      background: #fff;
      padding: 10px 15px 0;
      font-size: 0;

      .head-left {
        width: calc(100% - 50px);
        display: inline-block;
        text-align: start;

        .head-type {
          p {
            font-size: 14px;
            color: #ff98a0;
            display: inline-block;
            background: #fff5f6;
            border-radius: 30px;
            padding: 3px 18px;
            margin-bottom: 10px;
            position: relative;
            border: 1px solid #ff98a0;

            &:nth-child(2) {
              margin-left: 5px;
            }

            .icon {
              width: 7px;
              height: 7px;
              display: inline-block;
              border-top: 1px solid #ff98a0;
              border-right: 1px solid #ff98a0;
              transform: rotate(135deg);
              -webkit-transform: rotate(135deg);
              position: relative;
              left: 7px;
              top: -3px;
            }

            .brand-digit {
            }
          }
        }
      }

      .head-right {
        font-size: 14px;
        color: #000000;
        width: 50px;
        display: inline-block;
        text-align: right;
        vertical-align: top;
      }
    }

    .or-nav {
      border-top: 1px solid #f1f1f1;

      .van-tabs__wrap--scrollable .van-tab {
        flex: 0 0 19%;
      }

      /deep/ .van-tab--active {
        color: #ff98a0;
      }

      /deep/ .van-tabs__line {
        background-color: #ff98a0;
      }

      .brand-digit {
        position: absolute;
        top: 0;
        right: 0;
        color: #ff4444;
        font-size: 0.6em;
        line-height: 11px;
        padding: 3px 5px;
        background-color: #ffeded;
        border: 1px solid #ff534a;
        border-radius: 0.7em;
      }
    }
  }

  .van-list {
    padding: 0 0 10px;

    /deep/ .van-cell {
      .order-num {
        border-bottom: none;

        .right-cont {
          text-align: right;

          .cus-count-down {
            font-size: 11px;

            .van-count-down {
              color: #333;
            }
          }
        }
      }

      .product-img {
        background-color: #fcfcfc;
      }

      .order-amount {
        justify-content: flex-end;

        &:before {
          height: 0;
        }
      }

      .order-desc {
        text-align: right;

        p {
          color: #999;
        }
      }
    }
  }

  .van-popup {
    border-radius: 6px;
  }
}

.verify-cont {
  padding: 20px;

  > img {
    width: 220px;
    height: 220px;
  }

  .propmt {
    color: #333;
    font-size: 13px;
    padding-top: 20px;
    text-align: center;
  }
}
</style>