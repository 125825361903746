<template>
  <div class="cancel-reason">
    <div class="rea-tit">填写原因</div>
    <span class="m-close mui-icon icon--4" @click="isCancel = false"></span>
    <van-field v-model="message" rows="2" autosize type="textarea" maxlength="50" placeholder="点击输入内容,不少于10个字符" show-word-limit />
    <div class="rea-btn">
      <span @click="cancelOrder(1)">取消</span>
      <span @click="cancelOrder(2)">确定</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cancel",
  data() {
    return {
      message: ''
    }
  },
  methods: {
    cancelOrder(type) {
      let message = this.message;
      if (type === 2) {
        if (!message) return this.$toast("请填写取消原因");
        if (message.length < 10) return this.$toast("取消原因不能小于10个字!")
      }
      this.message = "";
      this.$emit("cancel", { type, message });
    }
  },
}
</script>

<style lang="scss" scoped>
.cancel-reason {
  width: 300px;
  padding: 10px 0;
  display: inline-block;
  background: #fff;
  border-radius: 6px;
  animation: fadeInUp 0.5s linear;

  .rea-tit {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    padding: 11px 15px;
    text-align: center;
    border-radius: 8px 8px 0 0;
  }

  .m-close {
    position: absolute;
    top: 0;
    right: 0;
    color: #333;
    font-size: 18px;

    &:before {
      padding: 10px;
      border-radius: 50%;
      display: inline-block;
      background-color: #f1f1f1;
      transform: scale(0.5);
      -webkit-transform: scale(0.5);
    }
  }

  .r-ti1 {
    font-size: 12px;
    color: #333;
    padding: 10px 0;
    text-align: center;
  }

  .text-area {
    padding: 0 20px;
    margin-bottom: 20px;

    textarea {
      width: 100%;
      padding: 10px;
      height: 80px;
      color: #999;
      border: none;
      border-radius: 6px;
      box-sizing: border-box;
      background-color: #f8f8f8;
    }
  }

  .rea-btn {
    font-size: 0;
    padding: 10px 15px;

    span {
      width: 48%;
      color: #333;
      padding: 5px 0;
      font-size: 14px;
      text-align: center;
      display: inline-block;
      border-radius: 33px;
      box-sizing: border-box;
      border: 1px solid #e5e5e5;

      &:nth-child(2) {
        color: #fff;
        margin-left: 4%;
        border-color: #e5e5e5;
        background-color: #ff98a0;
      }
    }
  }
}
</style>